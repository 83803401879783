/** @jsx jsx */

import { responsive } from "@boxoffice/screenplay";
import type { Props } from "gatsby-theme-pages/src/components/Widget/Spacer/Component";
import React, { memo } from "react";
import type { Property } from "shared/types/css";
import { jsx, StylePropertyValue } from "theme-ui";

const WidgetSpacer: React.FC<Props> = ({ size }) => {
  let paddingTop: StylePropertyValue<
    Property.PaddingTop<string | number> | undefined
  >;

  switch (size) {
    case "HUGE":
      paddingTop = responsive({ xs: 6, lg: 7 });
      break;
    case "BIG":
      paddingTop = responsive({ xs: 5, lg: 6 });
      break;
    case "MEDIUM":
      paddingTop = responsive({ xs: 4, lg: 5 });
      break;
    case "SMALL":
      paddingTop = responsive({ xs: 3, lg: 4 });
      break;
    default:
      paddingTop = "0px";
  }

  return (
    <div
      sx={{
        paddingTop,
      }}
    />
  );
};

export default memo(WidgetSpacer);
