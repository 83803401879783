/** @jsx jsx */
import { graphql } from "gatsby";
import React, { memo } from "react";
import { jsx } from "theme-ui";

import type { WidgetProps, WidgetUnion } from "../types";
import SpacerComponent from "./Component";

interface Props extends WidgetProps {
  widget: Extract<WidgetUnion, { __typename: "WidgetSpacer" }>;
}

const WidgetSpacer: React.FC<Props> = ({ widget }) => {
  return <SpacerComponent size={widget.spacerShape?.size} />;
};

export const query = graphql`
  fragment WidgetSpacer on WidgetSpacer {
    id
    __typename
    spacerShape {
      size
    }
  }
`;

export default memo(WidgetSpacer);
